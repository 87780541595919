/* eslint-disable import/no-unused-modules */
import React from 'react';

import {Card, CardContent, Typography, useTheme} from '@mui/material';
import {Box} from '@mui/system';

export default function NoSolarScoreCard() {
  const theme = useTheme();
  return (
    <Card
      sx={{
        borderRadius: theme.styles.card.radius.main,
        boxShadow: theme.palette.card.boxShadow.child,
      }}>
      <CardContent sx={{display: 'flex', paddingBottom: '8px !important'}}>
        <Box>
          <Box>
            <Typography
              variant="BodySmallBold"
              color={theme.palette.text.darkNavyBlue}>
              A Solar Score is currently unavailable for this address
            </Typography>
          </Box>

          <Box mt="4%" lineHeight="100%">
            <Typography variant="BodyExtraSmall">
              The address could be tied to a sectional title, or it&apos;s not
              in our partners&apos; database, or the metro area isn&apos;t
              covered just yet.
            </Typography>
          </Box>

          <Box mt="6%" lineHeight="70%">
            <Typography
              variant="BodyExtraSmall"
              sx={{...theme.styles.font.medium}}>
              Please search for an alternative address or view our energy
              solutions
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            maxWidth: '99px',
            display: 'center',
            alignItems: 'center',
            paddingRight: '5%',
          }}>
          <img src={theme.images.NoSolarScoreImage} alt="no-solar-score" />
        </Box>
      </CardContent>
    </Card>
  );
}
