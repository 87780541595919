import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter} from 'react-router-dom';
import {RecoilRoot} from 'recoil';

import App from './App';
import reportWebVitals from './reportWebVitals';
import HttpServices from './services/HttpServices';
import PingIdentityService from './services/PingIdentityServices';

const root = ReactDOM.createRoot(document.getElementById('root'));
PingIdentityService.getCsrfToken().then(token => {
  HttpServices.setToken(token);
  root.render(
    <React.StrictMode>
      <RecoilRoot>
        <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
          <App />
        </BrowserRouter>
      </RecoilRoot>
    </React.StrictMode>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
