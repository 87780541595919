import React, {Fragment} from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  useTheme,
} from '@mui/material';
import {Box} from '@mui/system';
import PropTypes from 'prop-types';

export default function SolarScoreToolTip({onClose, open}) {
  const theme = useTheme();
  const handleClose = () => {
    onClose();
  };

  return (
    <Box component={Dialog} onClose={handleClose} open={open} fullWidth>
      <DialogContent>
        <Box display="flex" flexDirection="row">
          <Box>
            <Typography
              variant="Header3"
              color={theme.palette.text.darkestBlue}>
              What is LookSee’s <br /> Solar Score?
            </Typography>
          </Box>
          <Box
            marginLeft="auto"
            data-id="link_content"
            data-scope="icon"
            data-intent="navigational"
            data-text="looksee | home | What is LookSee’s Solar Score? | close icon click">
            <img
              src={theme.icons.CloseIcon}
              alt="close"
              onClick={handleClose}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" my={2}>
          <Box
            p={2}
            sx={{
              width: '55%',
              border: 2,
              borderColor: `${theme.palette.primary.main}`,
              borderRadius: 4,
            }}>
            <Typography
              variant="BodySmall"
              color={theme.palette.text.darkestBlue}>
              Using lidar technology we analyse your:
            </Typography>
            <br />
            <Typography
              variant="BodySmall"
              color={theme.palette.text.darkestBlue}>
              {[
                'Roof direction,',
                'Roof area,',
                'Roof slope and',
                'Horizontal irradiation',
              ].map((item, index) => (
                <Fragment key={index}>
                  {index + 1}. {item}
                  <br />
                </Fragment>
              ))}
            </Typography>
          </Box>
          <Box marginRight="-15%">
            <img
              src={theme.icons.ToolTipCard1}
              alt="solar power"
              style={{
                position: 'relative',
                right: 40,
              }}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" my={2}>
          <Box marginLeft="-5%">
            <img
              src={theme.icons.ToolTipCard2}
              alt="solar score"
              style={{
                position: 'relative',
                bottom: '5%',
                left: '20%',
              }}
            />
          </Box>
          <Box
            p={2}
            sx={{
              width: '55%',
              height: '40%',
              border: 2,
              borderColor: `${theme.palette.primary.main}`,
              borderRadius: 4,
            }}>
            <Typography
              variant="BodySmall"
              color={theme.palette.text.darkestBlue}>
              Using this data to assess the solar feasibility of your home, we
              provide a Solar Score, <strong>between 0 and 100</strong> ranging
              from unfeasible to excellent
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" my={2}>
          <Box
            p={2}
            sx={{
              width: '55%',
              border: 2,
              borderColor: `${theme.palette.primary.main}`,
              borderRadius: 4,
            }}>
            <Typography variant="Body2" color={theme.palette.text.darkestBlue}>
              Armed with this knowledge you can&nbsp;
              <strong>request a solar quote to find the right-sized</strong>
              &nbsp;solar system for your home and pocket.
            </Typography>
          </Box>
          <Box marginRight="-5%">
            <img
              src={theme.icons.ToolTipCard3}
              alt="recommended system size"
              style={{
                position: 'relative',
                right: '8%',
                bottom: '-10%',
              }}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row-reverse">
          <Button
            data-id="link_content"
            data-scope="button"
            data-intent="navigational"
            data-text="looksee | home | What is LookSee’s Solar Score? | close button click"
            onClick={handleClose}>
            <strong>CLOSE</strong>
          </Button>
        </Box>
      </DialogContent>
    </Box>
  );
}

SolarScoreToolTip.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
