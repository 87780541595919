import React from 'react';

import {
  useTheme,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import {PropTypes} from 'prop-types';

import NativeBridgeServices from '../services/NativeBridgeServices';

const dialogVariations = [
  {
    variation: 'solarQuote',
    title: 'Continue to LookSee',
    body: 'You will be taken to our safe and secure LookSee site to get a free solar quote',
  },
  {
    variation: 'solarTip',
    title: 'Continue to LookSee',
    body: 'You will be taken to LookSee’s safe and secure Knowledge Hub articles',
  },
];

export default function ContinueDialog({variation, link, open, handleClose}) {
  const theme = useTheme();

  const att = dialogVariations.find(x => x.variation === variation);

  const handleOpenLink = () => {
    handleClose();
    const postMessage = {
      actionType: 'browser',
      metadata: {
        url: link,
      },
    };

    NativeBridgeServices.deepLink(postMessage);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          maxWidth: '80%',
        },
      }}>
      <DialogTitle>
        <Typography variant="Header1">{att.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography
            variant="DialogBody"
            color={theme.palette.text.darkNavyBlue}>
            {att.body}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{gap: '0px'}}>
        <Button
          data-id="link_content"
          data-scope="button"
          data-intent="navigational"
          data-text={`looksee | home | continue to lookSee | ${variation} | cancel button click`}
          onClick={() => handleClose()}
          variant="dialog">
          Cancel
        </Button>
        <Button
          data-id="link_content"
          data-scope="button"
          data-intent="navigational"
          data-text={`looksee | home | continue to lookSee | ${variation} | continue button click`}
          onClick={() => handleOpenLink()}
          variant="dialog">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ContinueDialog.propTypes = {
  variation: PropTypes.string,
  link: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
