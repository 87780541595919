import React from 'react';

import PropTypes from 'prop-types';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';

export function CircularProgressBar({children, ...props}) {
  return (
    <CircularProgressbarWithChildren {...props}>
      {children}
    </CircularProgressbarWithChildren>
  );
}
CircularProgressBar.propTypes = {
  children: PropTypes.node,
};
