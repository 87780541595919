import axios from 'axios';

import HttpServices from './HttpServices';
import config from '../config';

const makeBodiedRequest = async ({
  url,
  body,
  method = 'POST',
  headers,
  params,
}) => {
  try {
    const response = await axios({
      baseURL: config.proxyUrl,
      params,
      url,
      data: body,
      method,
      headers: headers,
    });
    return HttpServices.handleResponse(response);
  } catch (error) {
    throw Error(`An unexpected error occurred: ${error.message}`);
  }
};

const PingIdentityService = {
  async getCsrfToken() {
    const result = await HttpServices.get('/token/csrf');
    return result.token;
  },
  async rescopeToken(token, context) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'x-sbg-config': `APP_IDENTIFIER=STANDARD_BANK,OPERATING_SYSTEM=${context?.OPERATING_SYSTEM},APP_VERSION=${context?.APP_VERSION},CHANNEL=MOBILE`,
      'x-sbg-app-version': context?.APP_VERSION,
      'x-sbg-app-os': context?.OPERATING_SYSTEM,
      'x-sbg-zone': context?.ZONE,
    };
    try {
      return makeBodiedRequest({
        url: '/sbg-mobile/rest/security-gateway/v1/ping-token/rescope',
        headers,
        body: {
          clientId: config.ping.clientId,
          scopes: config.ping.scopes,
        },
      });
    } catch (error) {
      throw new Error(`Failed to retrieve ping auth tokens: ${error.message}`);
    }
  },
};

export default PingIdentityService;
