import config from '../config';
/* eslint-disable */

const NativeBridgeServices = {
  deepLink(message) {
    if (window.webkit && window.webkit.messageHandlers.DeepLinkingBridge) {
      window.webkit.messageHandlers.DeepLinkingBridge.postMessage(
        JSON.stringify(message),
      );
    } else if (window.DeepLinkingBridge) {
      window.DeepLinkingBridge.postMessage(JSON.stringify(message));
    }
  },
  openAddonFullWebView(context) {
    const nativeScopes = config.ping.scopes;
    const postMessage = {
      actionType: 'fullscreen_web_view',
      metadata: {
        url: window.location.origin + `${process.env.PUBLIC_URL}/landing`,
        context,
        tokenKey: nativeScopes,
        scopes: nativeScopes,
      },
    };

    this.deepLink(postMessage);
  },
  init({setContext, setPingToken}) {
    window.getAccessTokenCallback = function getAccessToken(token) {
      setPingToken(token);
    };
    window.getContextCallback = function getContextCallback(cxt) {
      setContext(cxt);
    };
  },
  initContext() {
    if (window.webkit && window.webkit.messageHandlers?.ContextBridge) {
      window.webkit.messageHandlers.ContextBridge.postMessage(null);
    } else if (window.ContextBridge) {
      window.ContextBridge.postMessage(null);
    }
  },
  initToken() {
    if (window.webkit && window.webkit.messageHandlers.SessionBridge) {
      window.webkit.messageHandlers.SessionBridge.postMessage(null);
    } else if (window.SessionBridge) {
      window.SessionBridge.postMessage(null);
    }
  },
  initTeardown() {
    if (window.webkit && window.webkit.messageHandlers.TearDownBridge) {
      window.webkit.messageHandlers.TearDownBridge.postMessage(null);
    } else if (window.TearDownBridge) {
      window.TearDownBridge.postMessage(null);
    }
  },
};

export default NativeBridgeServices;
