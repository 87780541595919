import React, {useCallback} from 'react';

import {useTheme} from '@emotion/react';
import {
  AppBar as MuiAppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import nativeBridgeServices from '../services/NativeBridgeServices';

export default function AppBar() {
  const theme = useTheme();
  const navigate = useNavigate();

  const onGoBack = useCallback(() => {
    const currentPage = window.location.pathname;
    if (currentPage === `${process.env.PUBLIC_URL}/landing`) {
      nativeBridgeServices.initTeardown();
    } else {
      navigate(-1);
    }
  }, []);

  return (
    <MuiAppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{mr: 2}}
          onClick={onGoBack}>
          <img src={theme.icons.BackArrowLeftIcon} width="100%" height="100%" />
        </IconButton>
        <Box component={Typography} variant="Header1" sx={{flexGrow: 1}}>
          LookSee
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
}
