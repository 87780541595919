import React, {useCallback, useState} from 'react';

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  useTheme,
} from '@mui/material';
import {
  useConditionalEffect,
  useDebouncedCallback,
  useToggle,
} from '@react-hookz/web';
import PropTypes from 'prop-types';

import LookSeeServices from '../services/LookSeeServices';

export default function SearchWidget({onSelect, setOnCancel, address}) {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [open, toggleOpen] = useToggle(false);
  const [addressSearch, toggleAddressSearch] = useToggle(!!address);
  const loading = open && !options.length;

  const handleAutocompleteSelect = useCallback(
    (e, searchResult) => {
      if (searchResult) {
        setSearchTerm(searchResult.description);
        onSelect(searchResult, false);
      } else {
        setOnCancel(previous => {
          return !previous;
        });
      }
    },
    [onSelect],
  );

  const getPropertySearchOptions = async searchQuery => {
    try {
      const response =
        await LookSeeServices.riskscapePropertySearch(searchQuery);
      if (response.searchTerm === searchQuery) {
        return response.results;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  useConditionalEffect(
    () => {
      (async () => {
        try {
          const response = await getPropertySearchOptions(address);
          if (response) {
            setOptions(response);
            if (response[0]) {
              setSearchTerm(response[0].description);
              onSelect(response[0], true);
            } else {
              setOnCancel(previous => {
                return !previous;
              });
            }
          } else {
            setOptions([]);
          }
        } catch (error) {
          setOptions([]);
        }
      })();
    },
    [address, onSelect],
    [address],
  );

  const debouncedSearch = useDebouncedCallback(
    async query => {
      try {
        const searchQuery = query.length > 50 ? query.substring(0, 51) : query;
        const response = await getPropertySearchOptions(searchQuery);
        if (response) {
          setOptions(response);
        } else {
          setOptions([]);
        }
      } catch (error) {
        setOptions([]);
      }
    },
    [],
    500,
  );

  useConditionalEffect(
    () => {
      debouncedSearch(searchTerm);
    },
    [searchTerm],
    [!!searchTerm, searchTerm.length > 4, !addressSearch],
  );

  return (
    <Box textAlign="right">
      <Autocomplete
        freeSolo
        inputValue={searchTerm}
        open={open}
        filterOptions={x => x}
        renderOption={(props, item) => (
          <li {...props} key={item.key}>
            {item.description}
          </li>
        )}
        onOpen={() => toggleOpen(true)}
        onClose={() => toggleOpen(false)}
        options={options}
        getOptionLabel={option => option.description}
        getOptionSelected={(option, value) =>
          option.address_id === value.address_id
        }
        loading={loading}
        onChange={handleAutocompleteSelect}
        onInputChange={(event, newInputValue) => {
          toggleAddressSearch(false);
          setSearchTerm(newInputValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            data-id="link_content"
            data-intent="informational"
            data-scope="input"
            data-text="looksee | home | address search click"
            label="Search for a different address"
            margin="normal"
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment>
                  <img src={theme.icons.SearchIcon} />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
              onKeyDown: e => {
                if (e.key === 'Enter') {
                  e.stopPropagation(); // Prevent the Enter button from working.
                }
              },
            }}
            InputLabelProps={{
              style: {
                marginLeft: searchTerm ? 'auto' : '25px',
              },
              shrink: !!searchTerm,
            }}
          />
        )}
      />
      {searchTerm && (
        <Button
          sx={{textTransform: 'none'}}
          style={{
            ...theme.typography.BodySmall,
          }}
          onClick={() => {
            setSearchTerm('');
            setOnCancel(previous => {
              return !previous;
            });
            setOptions([]);
          }}>
          Cancel
        </Button>
      )}
    </Box>
  );
}

SearchWidget.propTypes = {
  onSelect: PropTypes.func.isRequired,
  setOnCancel: PropTypes.func,
  address: PropTypes.string,
};
