import styled from '@emotion/styled';
import {Card, CardActions, CardContent} from '@mui/material';

export const SolarTipCardContent = styled(CardContent)`
  padding: 0px;
`;

export const SolarTipCard = styled(Card)`
  border-radius: 16px;
  box-shadow: ${({theme}) => theme.palette.card.boxShadow.main};
`;

export const SolarTipCardActions = styled(CardActions)`
  justify-content: flex-end;
`;
