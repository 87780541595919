import axios from 'axios';

import config from '../config/index';

const OK_STATUS_CODES = [200, 201];

const BASE_URL = config.services.baseUrl;

const APPLICATION_JSON_CONTENT_TYPE = 'application/json';

function createCsrfTokenStore() {
  let csrfToken = null;

  return {
    setToken: token => {
      csrfToken = token;
    },
    getToken: () => {
      return csrfToken;
    },
    removeToken: () => {
      csrfToken = null;
    },
  };
}

const csrfTokenStore = createCsrfTokenStore();

function createHeaders(headers = {}) {
  return {
    'Content-Type': APPLICATION_JSON_CONTENT_TYPE,
    'x-csrf-token': csrfTokenStore.getToken(),
    ...headers,
  };
}

export default {
  axiosInstance: axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
    headers: createHeaders(),
  }),
  getBaseUrl() {
    return BASE_URL;
  },
  clearToken() {
    csrfTokenStore.removeToken();
  },

  getToken() {
    return csrfTokenStore.getToken();
  },

  setToken(token) {
    csrfTokenStore.setToken(token);
  },

  createHeaders(headers = {}) {
    return {
      'Content-Type': APPLICATION_JSON_CONTENT_TYPE,
      'x-csrf-token': csrfTokenStore.getToken(),
      ...headers,
    };
  },

  async handleResponse(response) {
    if (response.status === 429) {
      throw Error(response.statusText);
    }
    const data = await response.data;

    if (OK_STATUS_CODES.includes(response.status)) {
      return data;
    } else {
      if (response.status === 401) {
        throw Error(data.error);
      } else if (Array.isArray(data)) {
        if (data.length > 0 && data[0].error) {
          throw Error(data[0].error);
        }
      } else if (data.error) {
        throw Error(data.error);
      } else if (response.status === 409) {
        throw data;
      }
      throw Error('Unexpected Error');
    }
  },

  async get(
    url,
    {alternativeBaseUrl, hasTimeOut, timeout, params, headers} = {},
  ) {
    try {
      const response = await this.axiosInstance({
        baseURL: alternativeBaseUrl || BASE_URL,
        params,
        url,
        method: 'GET',
        headers,
        timeout: hasTimeOut === true || timeout ? timeout || 8000 : undefined,
      });

      return this.handleResponse(response);
    } catch (error) {
      throw new Error('An unxpected error occurred', error);
    }
  },

  async post(
    url,
    {alternativeBaseUrl, hasTimeOut, timeout, body, params, headers} = {},
  ) {
    try {
      const response = await this.axiosInstance({
        baseURL: alternativeBaseUrl || BASE_URL,
        params,
        url,
        data: body,
        method: 'POST',
        headers: createHeaders(headers),
        timeout: hasTimeOut === true || timeout ? timeout || 8000 : undefined,
      });

      return this.handleResponse(response);
    } catch (error) {
      throw new Error('An unxpected error occurred', error);
    }
  },
};
