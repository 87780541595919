import {createTheme} from '@mui/material';

import BackArrowLeftIcon from './images/back-arrow-left-icon.svg';
import CloseIcon from './images/close-icon.svg';
import InfoIcon from './images/info-icon.svg';
import NoSolarScoreImage from './images/no-solar-score.svg';
import SearchIcon from './images/search-icon.svg';
import TipLightBulbIcon from './images/tip-light-bulb-icon.svg';
import ToolTipCard1 from './images/tool-tip-card-1.svg';
import ToolTipCard2 from './images/tool-tip-card-2.svg';
import ToolTipCard3 from './images/tool-tip-card-3.svg';

const MAX_VIEWPORT_WIDTH = 960;
const MIN_VIEWPORT_WIDTH = 600;
const ROOT = 16;

const convertPxToRem = (px, dec = 3) => Number((px / ROOT).toFixed(dec));
const calcViewportWidth = (maxWidth, minWidth, dec = 2) => {
  return (
    100 *
    ((maxWidth - minWidth) / (MAX_VIEWPORT_WIDTH - MIN_VIEWPORT_WIDTH))
  ).toFixed(dec);
};
const calcRem = (maxWidth, minWidth) => {
  return `${convertPxToRem(
    (MIN_VIEWPORT_WIDTH * maxWidth - MAX_VIEWPORT_WIDTH * minWidth) /
      (MIN_VIEWPORT_WIDTH - MAX_VIEWPORT_WIDTH),
  )}`;
};

const calcPreferredValue = (maxWidth, minWidth) => {
  if (!minWidth) {
    throw new Error('Error: Minimum width must be provided');
  }
  const viewportWidth = calcViewportWidth(maxWidth, minWidth);
  const remWidth = calcRem(maxWidth, minWidth);
  return `${viewportWidth}vw + ${remWidth}rem`;
};

const BoldWeight = {fontWeight: 700};
const MediumWeight = {fontWeight: 500};
const RegularWeight = {fontWeight: 400};
const LightWeight = {fontWeight: 300};

const GlobalTypography = {
  lineHeight: '130%',
};

const BoldTypography = {
  ...BoldWeight,
  fontFamily: 'BentonSansBold',
  ...GlobalTypography,
};

const MediumTypography = {
  ...MediumWeight,
  fontFamily: 'BentonSansMedium',
  ...GlobalTypography,
};

const RegularTypography = {
  ...RegularWeight,
  fontFamily: 'BentonSansRegular',
  ...GlobalTypography,
};

const LightTypography = {
  ...LightWeight,
  fontFamily: 'BentonSansRegular',
  ...GlobalTypography,
};

const PRIMARY_BLUE = '#0051FF';
const WHITE = '#FFFFFF';

export const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_BLUE,
    },
    secondary: {main: '#F4AD44'},
    tertiary: {main: '#0F213E'},
    text: {
      main: WHITE,
      primary: '#02070D',
      darkNavyBlue: '#1A314D',
      darkestBlue: '#000032',
      subHeading: '#212121',
    },
    card: {
      boxShadow: {
        main: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        child: 'box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15)',
        solarChip: '0px 1px 9px rgba(0, 0, 0, 0.09)',
      },
    },
    progressBar: {
      main: '#F2F2F2',
    },
  },
  icons: {
    TipLightBulbIcon,
    InfoIcon,
    SearchIcon,
    CloseIcon,
    ToolTipCard1,
    ToolTipCard2,
    ToolTipCard3,
    BackArrowLeftIcon,
  },
  images: {
    NoSolarScoreImage,
  },
  styles: {
    font: {
      family: {
        bentonSansBold: 'BentonSansBold',
        bentonSansMedium: 'BentonSansMedium',
        bentonSansRegular: 'BentonSansRegular',
      },
      weight: {
        BoldWeight,
        MediumWeight,
        RegularWeight,
        LightWeight,
      },
      bold: BoldTypography,
      medium: MediumTypography,
      regular: RegularTypography,
      light: LightTypography,
    },
    card: {
      radius: {
        main: '16px',
      },
    },
    textField: {
      radius: {main: '8px'},
    },
  },
  typography: {
    fontFamily: 'BentonSansRegular',
    Header1: {
      fontSize: `clamp(1.25rem, ${calcPreferredValue(50, 20)}, 3.125rem)`,
      ...MediumTypography,
    },
    Header2: {
      fontSize: `clamp(1.5rem, ${calcPreferredValue(54, 24)}, 3.375rem)`,
      ...RegularTypography,
    },
    Header3: {
      fontSize: `clamp(1.125rem, ${calcPreferredValue(24, 18)}, 1.5rem)`,
      ...BoldTypography,
    },
    Header4: {
      fontSize: '1rem',
      ...MediumTypography,
    },
    Subtitle1: {
      fontSize: `clamp(0.875rem, ${calcPreferredValue(24, 14)}, 1.5rem)`,
      ...RegularTypography,
    },
    Subtitle2: {
      fontSize: `clamp(0.875rem, ${calcPreferredValue(16, 14)}, 1rem)`,
      ...LightTypography,
    },
    Subtitle3: {
      fontSize: `clamp(0.75rem, ${calcPreferredValue(14, 12)}, 0.875rem)`,
      ...MediumTypography,
    },
    BodySmall: {
      fontSize: `clamp(0.75rem, ${calcPreferredValue(14, 12)}, 0.875rem)`,
      ...RegularTypography,
    },
    BodyExtraSmall: {
      fontSize: `clamp(0.625rem, ${calcPreferredValue(12, 10)}, 0.75rem)`,
      ...RegularTypography,
    },
    BodySmallBold: {
      fontSize: `clamp(0.75rem, ${calcPreferredValue(14, 12)}, 0.875rem)`,
      ...BoldTypography,
    },
    Body1: {
      fontSize: '1rem',
      ...RegularTypography,
    },
    Body2: {
      fontSize: `clamp(0.875rem, ${calcPreferredValue(18, 14)}, 1.125rem)`,
      ...RegularTypography,
    },
    LinkReg: {
      fontSize: '1rem',
      ...RegularTypography,
    },
    Button: {
      fontSize: '0.875rem',
      ...BoldTypography,
    },
    DialogBody: {
      fontSize: `clamp(0.875rem, ${calcPreferredValue(20, 16)}, 1rem)`,
      ...RegularTypography,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          Header1: 'h1',
          Header2: 'h2',
          Header3: 'h3',
          Header4: 'h4',
          Subtitle1: 'h6',
          Subtitle2: 'h6',
          Subtitle3: 'h6',
          BodySmall: 'p',
          BodySmallBold: 'p',
          Body1: 'p',
          Body2: 'p',
          LinkReg: 'a',
          DialogBody: 'p',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: {variant: 'submit'},
          style: {
            width: '100%',
            height: '48px',
            padding: '6px 16px',
            backgroundColor: PRIMARY_BLUE,
            color: WHITE,
            borderRadius: '8px',
            fontSize: `clamp(0.875rem, ${calcPreferredValue(16, 14)}, 1rem)`,
            fontWeight: 700,
          },
        },
        {
          props: {variant: 'dialog'},
          style: {
            fontSize: `clamp(0.875rem, ${calcPreferredValue(16, 14)}, 1rem)`,
            fontWeight: 700,
            color: '#0062E1',
            padding: '6px 8px',
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        },
        input: {
          fontWeight: 400,
          fontSize: `clamp(0.875rem, ${calcPreferredValue(16, 14)}, 1rem)`,
        },
        listbox: {
          fontWeight: 400,
          fontSize: `clamp(0.875rem, ${calcPreferredValue(16, 14)}, 1rem)`,
        },
      },
    },
  },
});
