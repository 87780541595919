const dataLayerVariables = ({
  pageName = '',
  pageSubSection1 = '',
  pageSubSection2 = '',
  pageCategory = 'Personal',
}) => {
  return {
    pageCategory,
    pageName,
    pageSubSection1,
    pageSubSection2,
  };
};

export const updateDataLayerUserVariables = ({
  bpidguid = '',
  customerGuid = '',
  email_sha256 = '',
  hashed_msisdn = '',
}) => {
  window.dataLayer = {
    ...window.dataLayer,
    bpidguid,
    customerGuid,
    email_sha256,
    hashed_msisdn,
  };
};

export const sendPageAnalyticsByPath = path => {
  try {
    switch (path) {
      case `${process.env.PUBLIC_URL}`:
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageName: 'looksee:widget',
            pageSubSection1: 'looksee:widget',
          }),
        };
        break;
      case `${process.env.PUBLIC_URL}/landing`:
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageName: 'looksee:home',
            pageSubSection1: 'looksee:home',
          }),
        };
        break;
      default:
        return;
    }
    window._satellite?.track('globalVirtualPageView');
  } catch (error) {
    console.log(error);
  }
};

export const sendComponentAnalytics = (data, track) => {
  try {
    window.dataLayer = {
      ...window.dataLayer,
      ...data,
    };
    if (track) {
      window._satellite?.track(track);
    }
  } catch (error) {
    console.log(error);
  }
};
