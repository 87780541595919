/* eslint-disable import/no-unused-modules */
import React from 'react';

import {
  Card,
  Paper,
  CardContent,
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import {PropTypes} from 'prop-types';

import {CircularProgressBar} from './CircularProgressBar';
import {GradientSVG} from './GradientSVG';

const scoreAttributes = [
  {
    checkScore: score => score < 40,
    body: (
      <>
        Your property will not benefit from a solar installation due to limited
        sun-generated power. But you are a{' '}
        <Typography variant="BodySmallBold" style={{display: 'inline'}}>
          great candidate for our backup power solutions
        </Typography>
      </>
    ),
    gradientColor: {
      startColor: '#FFC1AD',
      middleColor: '#F0FD9F',
      endColor: '#008805',
    },
    description: 'Unfeasible',
    chipBackgroundColor: '#FFC1AD',
    chipColor: '#E94600',
  },

  {
    checkScore: score => score < 50,
    body: (
      <>
        <Typography variant="BodySmallBold" style={{display: 'inline'}}>
          Your property could benefit from an entry-level solar installation
        </Typography>
        , but the output from the system could potentially be limited
      </>
    ),
    gradientColor: {
      startColor: '#FFC1AD',
      middleColor: '#F0FD9F',
      endColor: '#9EFF8E',
    },
    description: 'Low',
    chipBackgroundColor: '#FFEBCE',
    chipColor: '#FF8A00',
  },
  {
    checkScore: score => score < 60,
    body: (
      <>
        <Typography variant="BodySmallBold" style={{display: 'inline'}}>
          Your property could benefit from a solar installation
        </Typography>{' '}
        due to a combination of roof area and its northern facing cardinal
        direction
      </>
    ),
    gradientColor: {startColor: '#FFEDAD', endColor: '#18A315'},
    description: 'Average',
    chipBackgroundColor: '#FFEDAD',
    chipColor: '#FFA200',
  },
  {
    checkScore: score => score < 70,
    body: (
      <>
        Your property offers{' '}
        <Typography variant="BodySmallBold" style={{display: 'inline'}}>
          above-average feasibility for a solar installation
        </Typography>{' '}
        and might be able to support an ‘off-the-grid’ system
      </>
    ),
    gradientColor: {startColor: '#F0FD9F', endColor: '#009906'},
    description: 'Good',
    chipBackgroundColor: '#E0FFC8',
    chipColor: '#22842C',
  },
  {
    checkScore: () => true,
    body: (
      <>
        Your property has the{' '}
        <Typography variant="BodySmallBold" style={{display: 'inline'}}>
          best Solar installation potential
        </Typography>{' '}
        and could allow you to go ‘off-the-grid’
      </>
    ),
    description: 'Excellent',
    gradientColor: {startColor: '#F0FD9F', endColor: '#009906'},
    chipBackgroundColor: '#D1FFBC',
    chipColor: '#22842C',
  },
];

export default function SolarScoreCard({score: score}) {
  const theme = useTheme();
  const scoreAtt = scoreAttributes.find(x => x.checkScore(score));

  return (
    <Card
      sx={{
        borderRadius: theme.styles.card.radius.main,
        boxShadow: theme.palette.card.boxShadow.child,
      }}>
      <CardContent sx={{display: 'flex', gap: '10%'}}>
        <Box
          sx={{
            maxWidth: '40%',
          }}>
          <GradientSVG
            id="solarscore"
            startColor={scoreAtt.gradientColor.startColor}
            middleColor={scoreAtt.gradientColor.middleColor}
            endColor={scoreAtt.gradientColor.endColor}
            transformation="rotate(90)"
          />
          <CircularProgressBar
            value={score.toFixed()}
            circleRatio={0.6}
            strokeWidth={10}
            styles={{
              root: {
                strokeLinecap: 'round',
                '-webkit-transform': 'rotate(252deg)',
              },
              path: {stroke: 'url(#solarscore)', strokeLinecap: 'round'},
              trail: {
                stroke: theme.palette.progressBar.main,
              },
            }}>
            <Paper
              sx={{
                backgroundColor: theme.palette.text.main,
                marginTop: '-10%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                width: '65%',
                height: '65%',
                boxShadow: theme.palette.card.boxShadow.solarChip,
              }}>
              <Typography
                variant="Header2"
                color={theme.palette.text.darkestBlue}>
                {score.toFixed()}
              </Typography>
            </Paper>
          </CircularProgressBar>
          <Box
            mt="-30%"
            sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography
              variant="BodyExtraSmall"
              color={theme.palette.text.subHeading}
              ml="5%">
              0
            </Typography>
            <Typography
              variant="BodyExtraSmall"
              color={theme.palette.text.subHeading}
              mr="5%">
              100
            </Typography>
          </Box>
          <Typography
            variant="BodySmallBold"
            color={scoreAtt.chipColor}
            mt="5%"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {scoreAtt.description.toUpperCase()}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'center',
            alignItems: 'center',
          }}>
          <Typography variant="BodySmall">{scoreAtt.body}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

SolarScoreCard.propTypes = {
  score: PropTypes.number,
};
