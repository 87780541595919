import {atom} from 'recoil';

export default {
  solarScore: atom({
    key: 'solarScore',
    default: null,
  }),
  primaryAddressSolarScoreResult: atom({
    key: 'primaryAddressSolarScoreResult',
    default: null,
  }),
};
