import React from 'react';

import {useTheme, Button} from '@mui/material';
import PropTypes from 'prop-types';

import {
  SolarTipCard as Card,
  SolarTipCardActions,
  SolarTipCardContent,
} from './style';
import SolarTipWidget from '../SolarTipWidget';

export default function SolarTipCard({title, tip, handleClick}) {
  const theme = useTheme();

  return (
    <Card>
      <SolarTipCardContent>
        <SolarTipWidget title={title} tip={tip} isContent />
      </SolarTipCardContent>
      <SolarTipCardActions>
        <Button
          data-id="link_content"
          data-intent="informational"
          data-scope="button"
          data-text="looksee | home | learn more link click"
          size="small"
          style={{
            ...theme.typography.BodySmall,
            color: theme.palette.text.darkestBlue,
          }}
          onClick={handleClick}>
          Learn More
        </Button>
      </SolarTipCardActions>
    </Card>
  );
}

SolarTipCard.propTypes = {
  title: PropTypes.string,
  tip: PropTypes.string,
  handleClick: PropTypes.func,
};
