import './App.css';
import React, {useEffect, useState} from 'react';

import {ThemeProvider} from '@mui/material';
import {useConditionalEffect} from '@react-hookz/web';
import {sha256} from 'js-sha256';
import {jwtDecode} from 'jwt-decode';
import {Route, Routes} from 'react-router-dom';
import {useRecoilState, useSetRecoilState} from 'recoil';

import atoms from './atoms';
import config from './config';
import LandingPage from './pages/LandingPage';
import StoreWidget from './pages/StoreWidget';
import LookSeeServices from './services/LookSeeServices';
import NativeBridgeServices from './services/NativeBridgeServices';
import PingIdentityServices from './services/PingIdentityServices';
import {theme} from './theme';
import {
  sendPageAnalyticsByPath,
  updateDataLayerUserVariables,
} from './utils/Analytics';

function App() {
  const [dailyTip, setDailyTip] = useRecoilState(atoms.dailyTip);
  const [context, setContext] = useRecoilState(atoms.context);
  const [bridgeToken, setBridgeToken] = useRecoilState(atoms.bridgeToken);
  const [token, setToken] = useState(null);
  const setUser = useSetRecoilState(atoms.user);

  const getDailyTip = async () => {
    const result = await LookSeeServices.getDailyTip();
    setDailyTip(result);
  };

  const populateUserData = async () => {
    const decodedToken = jwtDecode(token);
    const bpidguid = decodedToken?.bpidguid;
    const info = await fetchUserData();
    const userData = {
      bpidguid,
      ...info,
    };

    setUser(currentUser => {
      if (currentUser) {
        return {
          ...currentUser,
          ...userData,
        };
      }
      return {
        ...userData,
      };
    });

    let userAddress = null;

    if (bpidguid) {
      userAddress = await fetchUserAddress();
    } else {
      userAddress = '';
    }

    setUser(currentUser => {
      if (currentUser) {
        return {
          ...currentUser,
          userAddress,
        };
      }
      return {
        userAddress,
      };
    });

    updateDataLayerUserVariables({
      email_sha256: sha256(info?.email).toString(),
      customerGuid: decodedToken?.id,
    });
  };

  const getRiskscapeFriendlyAddress = usersAddress => {
    if (Array.isArray(usersAddress) && usersAddress.length > 0) {
      const {streetPOBoxNo, suburb, city, postalCode} = usersAddress[0];

      return `${streetPOBoxNo}, ${suburb}, ${city}, ${postalCode}`;
    }
    return '';
  };

  useConditionalEffect(
    () => {
      getDailyTip();
      return () => {};
    },
    [dailyTip],
    [!dailyTip],
  );

  useConditionalEffect(
    () => {
      sendPageAnalyticsByPath(location.pathname);
    },
    [location, window._satellite?.track],
    [window._satellite?.track],
  );

  useEffect(() => {
    if (config.env !== 'local') {
      NativeBridgeServices.init({setContext, setPingToken: setBridgeToken});
      NativeBridgeServices.initContext();
      NativeBridgeServices.initToken();
    }
  }, [setBridgeToken, setContext]);

  const rescopeToken = async (pingToken, appContext) => {
    const rescopedToken = await PingIdentityServices.rescopeToken(
      pingToken,
      appContext,
    );
    const {accessToken} = rescopedToken ?? null;
    setToken(accessToken);
    refreshRescopedToken();
  };

  const reScopeBridgeToken = async appContext =>
    rescopeToken(bridgeToken.token, appContext.context);

  async function fetchUserData() {
    try {
      const userInfo = await LookSeeServices.fetchUserInfo(token);
      return userInfo;
    } catch (error) {
      return null;
    }
  }

  async function refreshRescopedToken() {
    setTimeout(async () => {
      await rescopeToken(bridgeToken.token, context);
    }, 180000);
  }

  async function fetchUserAddress() {
    try {
      const userAddress = await LookSeeServices.getAddress(token);
      return getRiskscapeFriendlyAddress(userAddress);
    } catch (error) {
      return '';
    }
  }

  useEffect(() => {
    if (context && bridgeToken) {
      reScopeBridgeToken(context);
    }
  }, [context, bridgeToken]);

  useConditionalEffect(
    () => {
      populateUserData();
      return () => {};
    },
    [token],
    [!!token],
  );

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" Component={StoreWidget} />
        <Route path="/landing" Component={LandingPage} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
