import React from 'react';

import {Box, useTheme} from '@mui/material';
import {useRecoilValue} from 'recoil';

import atoms from '../atoms';
import SolarTipWidget from '../components/SolarTipWidget';
import nativeBridgeServices from '../services/NativeBridgeServices';

export default function StoreWidget() {
  const theme = useTheme();
  const dailyTip = useRecoilValue(atoms.dailyTip);
  const context = useRecoilValue(atoms.context);
  document.body.style.backgroundColor = theme.palette.text.main;

  return (
    <Box
      data-id="link_content"
      data-intent="navigational"
      data-scope="button"
      data-text="looksee | widget | tile click"
      height="168px"
      onClick={() => nativeBridgeServices.openAddonFullWebView(context)}>
      {dailyTip && (
        <SolarTipWidget
          title={dailyTip.title}
          tip={dailyTip.tip}
          height="168px"
        />
      )}
    </Box>
  );
}
