import {atom} from 'recoil';

export default {
  user: atom({
    key: 'user',
    default: null,
  }),
  context: atom({
    key: 'context',
    default: undefined,
  }),
  bridgeToken: atom({
    key: 'bridgeToken',
    default: undefined,
  }),
  userAddress: atom({
    key: 'userAddress',
    default: undefined,
  }),
};
