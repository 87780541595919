import HttpServices from './HttpServices';

const LookSeeServices = {
  async getDailyTip() {
    return HttpServices.get(`addon/tips`);
  },
  async riskscapePropertySearch(query) {
    return HttpServices.get(
      `/property/find?s=${encodeURIComponent(query)}&provider_id=riskscape`,
      {hasTimeOut: true, timeout: 10000},
    );
  },
  getSolarScore(propertyKey, subplaceId) {
    return HttpServices.get(
      `/solar/score?property_key=${propertyKey}&subplace_id=${subplaceId}`,
    );
  },
  fetchUserInfo(accessToken) {
    return HttpServices.get('addon/login', {
      headers: {'x-addon-user-token': `${accessToken}`},
    });
  },
  getAddress(accessToken) {
    return HttpServices.get(`/addon/user-address`, {
      headers: {'x-addon-user-token': accessToken},
    });
  },
};

export default LookSeeServices;
