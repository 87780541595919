export default {
  env: process.env.REACT_APP_ENV,
  proxyUrl: process.env.REACT_APP_PROXY_URL,
  services: {
    baseUrl: process.env.REACT_APP_BASE_URL,
    applicationId: process.env.REACT_APP_APPLICATION_ID,
  },
  ping: {
    baseUrl: process.env.REACT_APP_PING_BASE_URL,
    scopes: process.env.REACT_APP_RESCOPE_SCOPES,
    clientId: process.env.REACT_APP_PING_CLIENT_ID,
  },
};
