import React from 'react';

import {useTheme} from '@emotion/react';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';

export default function SolarTipWidget({
  title,
  tip,
  isContent = false,
  height,
}) {
  const theme = useTheme();
  return (
    <Box
      height={height}
      display="flex"
      flexDirection="column"
      padding={1.5}
      gap={2}
      bgcolor={theme.palette.primary.main}
      borderRadius={isContent ? '0px' : '8px'}>
      <Box display="flex" gap={1} alignItems="center">
        <Box>
          <img src={theme.icons.TipLightBulbIcon} alt="icon" />
        </Box>
        <Box>
          <Typography variant="Subtitle3" color={theme.palette.text.main}>
            Daily tip - Solar
          </Typography>
        </Box>
      </Box>
      <Box display="flex" gap={4} alignItems="flex-end">
        <Box width="60%">
          <Typography variant="Header3" color={theme.palette.text.main}>
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography variant="BodySmall" color={theme.palette.text.main}>
            {tip}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

SolarTipWidget.propTypes = {
  title: PropTypes.string.isRequired,
  tip: PropTypes.string.isRequired,
  isContent: PropTypes.bool,
  height: PropTypes.string,
};
